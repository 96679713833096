.services-cards-main{
	padding: 40px; margin-top: -100px ; position: relative; z-index: 1;
	.items-holder{--grid-item-width : 4; display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 20px;}

	@media(max-width: 1024px){
        padding: 10px 20px; margin-top: -30px
        
    }
}

.slick-track{display: flex; gap: 20px;}
.slick-arrow{--size: 55px; bottom: -80px; left: calc(50% + 40px); top: initial; transform: translateX(-50%); height: var(--size); width: var(--size); border-radius: var(--size); border: 2px solid #262626; display: flex !important; align-items: center; justify-content: center; background: var(--content-color); color: white}
.slick-arrow:hover{color: var(--content-color)}
.slick-arrow.slick-disabled{border: 2px solid #262626; background: transparent; color: var(--content-color)}
.slick-arrow::before{content:""; display: none}
.slick-arrow.slick-prev{left: calc(50% - 40px);}



.testimonials-main, .featured-services{padding-bottom: 120px}
.featured-services, .projects-section{
	position: relative;
	.container{position: relative;}
	.animated-primary-button{top: 50%; position: absolute; right: 0px; transform: rotate(-90deg) translate(-50%, 85px);display: inline-flex;}

	@media (max-width: 768px) {
		padding-right: 40px;
	}
}

.home-before-after-holder{
	.before-after-slider{
		.before-after-slider__first-photo-container, .before-after-slider__second-photo-container{max-height: 800px;}
		.before-after-slider__first-photo-container img, .before-after-slider__second-photo-container img{display: block; object-position: bottom; object-fit: cover; width: 100%;}
	}
}
.projects-galary-modal{
	.modal-body{padding: 0}
	.close-modal{position: absolute; top: 20px; right: 30px; z-index: 9; background: white; padding: 8px; color: var(--content-color);}
	
	.thumbnail-slider-wrap{position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); z-index: 9; width: 100%; background: rgba(0, 0, 0, 0.4); padding: 10px;}
	.thumbnail-slider-wrap .galary-thumb{height: 80px; border: 2px solid #fff; border-radius: 10px; overflow: hidden;}
	.before-after-slider img{max-height: 100vh; object-fit: cover; object-position: center;}
}

.email-submition-modal{
	.modal-body{padding: 100px 50px;}
	.email-submition-modal-form-wrap{display: grid; grid-gap: 20px; justify-items: center; text-align: center;}
	.modal-title{font-size: 48px;}
	.modal-desc{font-size: 24px; font-weight: 700;}
	.primary-input-holder{width: 75%;}
	.close-modal{position: absolute; top: 20px; right: 30px; z-index: 9; padding: 8px; color: var(--content-color);}
	
	@media (max-width: 1024px) {
		.modal-body{padding: 50px 20px;}
		.modal-title{font-size: 20px}
		.modal-desc{font-size: 16px;}
		.primary-input-holder{width: 100%;}
		
	}
}

.animated-primary-button{

	button, a{animation: buttonScale 1s infinite; display: flex; align-items: center; gap: 10px; font-weight: 700;}

	@keyframes buttonScale {
		0% {transform: scale(0.7);}
		50% {transform: scale(0.8);}
		100% {transform: scale(0.7);}
	}
}

