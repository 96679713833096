.dashboard-login-main{
	background: #f1f1f1; padding: 30px; height: 100vh; display: flex; align-items: center; justify-content: center;

	.content-holder-main{padding: 30px; display: grid; grid-gap: 20px; background: white; box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15); border-radius: 20px; overflow: hidden; width: 100%; max-width: 450px;}
	
	h4{text-align: center;}
	.form-control {
		border: 1px solid #ccc;
		height: 50px;
	}
}